<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title">
      <template #buttons >
        <b-button  @click.prevent="clockInOut" style="margin: 5px" variant="primary"><b-spinner class="mx-4" small v-if="loading" /><span v-if="!loading">{{ clockedIn ? 'End Job' : 'Start Job' }}</span></b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <div>
        <div v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </div>
        <div class="py-4 text-center" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </div>
      <div v-if="!loading && !error">
        <b-row class="mb-4">
          <b-col cols="12" lg="4">
            <b-card class="h-100" title="Details" v-if="data">
              <p class="mb-0"><strong>Title: </strong> {{ data.summary }}</p>
              <p class="mb-0"><strong>Description: </strong> {{ data.description }}</p>
            </b-card>
          </b-col>
          <b-col cols="12" lg="4">
            <b-card class="h-100" title="Location Address" v-if="data">
              <p v-html="data.property.address && data.property.address.address_snippet.split(',').join(',<br/>').replace(data.property.address.postcode, `<br/>${data.property.address.postcode}`)"></p>
            </b-card>
          </b-col>
          <b-col cols="12" lg="4">
            <b-card class="h-100" title="Access" v-if="data.property?.key">
              <p class="mb-0"><strong>Key Hook: </strong>{{ data.property.key.key_hook }}</p>
              <p class="mb-0"><strong>Key Box: </strong>{{ data.property.key.keybox_code }}</p>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card class="h-100" title="Job Activity" v-if="data.attendance && (data.attendance.length > 0)">
              <p class="mb-0" v-for="attendance in data.attendance" :key="attendance.id">
                {{ attendance.user.name }} |
                {{ moment(attendance.start).format('DD/MM/YYYY HH:mm') }} - {{ attendance.end ? moment(attendance.end).format('HH:mm') : 'Ongoing' }} |
                {{ (attendance.end ? moment(attendance.end) : moment()).diff(moment(attendance.start), 'hours') }} hours {{ (attendance.end ? moment(attendance.end) : moment()).diff(moment(attendance.start), 'minutes')%60 }} minutes
              </p>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mb-4" v-if="dataColumns">
          <b-col cols="12">
            <b-card body-class="p-0">
              <b-list-group flush>
                <b-list-group-item  style="background-color: rgba(0, 0, 0, 0.03)">
                  <div class="d-flex flex-row">
                    <p class="mb-0">Job History</p>
                  </div>
                </b-list-group-item>
                <b-list-group-item :key="comment.id" v-for="comment in data.comments">
                  <b-row>
                    <b-col cols="12">
                      <p class="mb-0">{{ comment.message }}</p>
                      <p class="text-muted">- {{comment.user.name }}, {{ moment(comment.created_at).format('DD/MM/YYYY @ HH:mm') }}</p>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Comment">
                        <b-form-textarea type="text" v-model="commentMessage"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <div class="d-flex flex-row">
                        <b-form-select class="w-100" id="status" v-model="columnStatus" :options="map(dataColumns, column => ({
                          value: column.id,
                          text: column.name
                        }))" />
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col cols="12">
                      <div class="d-flex flex-row">
                        <b-button @click.prevent="addNewComment" variant="primary">Save Update</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card body-class="p-0">
              <b-list-group flush>
                <b-list-group-item style="background-color: rgba(0, 0, 0, 0.03)">
                  <div class="d-flex flex-row justify-content-between">
                    <p class="mb-0 my-auto">Visits</p>
                    <b-button @click.prevent="addVisit" variant="primary" v-if="can('Maintenance/Create')">Add Visit...</b-button>
                  </div>
                </b-list-group-item>
                <b-list-group-item :key="visit.id" v-for="visit in data.visits">
                  <b-row>
                    <b-col cols="10">
                      <b-form-group label="Summary">
                        <b-form-input type="text" v-model="visit.summary"/>
                      </b-form-group>
                    </b-col>
                    <b-col class="d-flex flex-column justify-content-end" cols="2">
                      <b-button block class="mb-3" @click.prevent="removeVisit(visit)" variant="danger" v-if="can('Maintenance/Create')"><fa-icon icon="trash" /></b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <template #label>
                          <label class="mb-0">Date</label>
                        </template>
                        <b-form-datepicker v-model="visit.visit_at" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group>
                        <template #label>
                          <label class="mb-0">Start Time</label>
                        </template>
                        <b-form-timepicker v-model="visit.start_time" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group>
                        <template #label>
                          <label class="mb-0">End Time</label>
                        </template>
                        <b-form-timepicker v-model="visit.end_time" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item v-if="can('Maintenance/Create')">
                  <b-button @click.prevent="saveMaintenance" variant="primary">
                    <b-spinner class="mx-4" variant="light" small v-if="saving"/>
                    <span v-if="!saving">Save Visits</span>
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: { StickyHeader },
  data() {
    return {
      validationErrors: {},
      error: undefined,
      genericError: undefined,
      data: {},
      dataColumns: {},
      commentMessage: '',
      columnStatus: null,
      saving: false,
    };
  },
  async mounted() {
    const maintenance = await this.fetchSingle(this.$route.params.maintenanceId);

    this.data = maintenance;
    this.setClockedIn(maintenance.is_clocked_in);

    this.columnStatus = this.data.maintenance_stage_id;

    this.dataColumns = await this.fetch();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('maintenance', ['loading', 'saving', 'clockedIn']),
  },
  methods: {
    map,
    ...mapActions('maintenance', ['fetch', 'fetchSingle', 'updateOrCreate', 'addComment', 'clockIn', 'clockOut', 'setClockedIn']),
    moment,
    can(perm) {
      let pass = false
      this.user.permissions.forEach((permission) => {
        if (permission.name == perm) pass = true;
      });

      return pass;
    },
    addVisit() {
      this.data.visits.push({
        id: null,
        visit_at: null,
        leave_at: null,
        summary: ''
      });
    },
    removeVisit(visit) {
      this.data.visits.splice(this.data.visits.indexOf(visit), 1);
    },
    clockInOut() {
      if (this.clockedIn) {
        this.clockOut(this.$route.params.maintenanceId).then((data) => {
          this.$bvModal.msgBoxOk(data.message, {
            title: 'End Job',
            okVariant: data.clockedIn ? 'danger' : 'primary',
            centered: true
          });
        }).catch(() => {
          this.$bvModal.msgBoxOk('There was an error clocking you out of this job.', {
            title: 'End Job',
            okVariant: 'danger',
            centered: true
          });
        });

        this.fetchSingle(this.$route.params.maintenanceId).then(response => this.data = response);
      } else {
        this.clockIn(this.$route.params.maintenanceId).then((data) => {
          this.$bvModal.msgBoxOk(data.message, {
            title: 'Start Job',
            okVariant: !data.clockedIn ? 'danger' : 'primary',
            centered: true,
            okTitle: data.job ? 'View Job' : 'Ok'
          }).then(() => {
            if (data.job) {
              this.$router.push({name: 'jobs.details', params: {'jobId': data.job.id}});
            }
          });

          this.fetchSingle(this.$route.params.maintenanceId).then(response =>  this.data = response);
        }).catch(() => {
          this.$bvModal.msgBoxOk('There was an error clocking you in to this job.', {
            title: 'Start Job',
            okVariant: 'danger',
            centered: true
          });
        })
      }
    },
    saveMaintenance() {
      this.saving = true;
      this.updateOrCreate(this.data).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetchSingle(this.$route.params.maintenanceId).then(response => this.data = response);
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    addNewComment() {
      this.addComment({
        maintenanceId: this.$route.params.maintenanceId,
        message: this.commentMessage,
        column: this.columnStatus
      })
        .then(() => {
          this.commentMessage = '';
          return this.fetchSingle(this.$route.params.maintenanceId).then(response => this.data = response);
        });
    }
  },
  watch: {
    async '$route'() {
      const maintenance = await this.fetchSingle(this.$route.params.maintenanceId);

      this.data = maintenance;
    }
  }
}
</script>

<style>

</style>
